
<!-- this component should not use slots -->
<!-- this component should not inherit attrs -->

<!-- need to test mobile when making any changes -->

<script lang="ts">
    export interface IProps {
        /* size (default md) */
        size?: "sm" | "md" | "lg";
        /* show loading indicator */
        loading?: boolean;
        /* disabled */
        disabled?: boolean;
    };

    export interface IEmits {
        (e: "change", val: boolean): void;
    };

    const SIZES_MOBILE  = { "sm": "1.00em", "md": "1.25em", "lg": "1.50em" } as const;
    const SIZES_DESKTOP = { "sm": "0.75em", "md": "1.00em", "lg": "1.25em" } as const;
</script>

<script setup lang="ts">
    import { ref, computed, watch } from "vue";
    import { isMobile } from "o365.GlobalState.ts";

    const props = withDefaults(defineProps<IProps>(), { size: "md" });
    const emit = defineEmits<IEmits>();
    const model = defineModel<boolean>();

    const isLoading = computed<boolean>(() => props.loading);
    // const isDisabled = computed<boolean>(() => props.disabled || props.loading);
    const isDisabled = computed<boolean>(() => props.disabled);

    function getFontSize(): string {
        return (isMobile.value ? SIZES_MOBILE : SIZES_DESKTOP)[props.size] ?? "1.00em";
    }

    watch(model, (val) => emit("change", !!val));

    // cache fix
</script>

<template>
    <div>
        <!-- touch area -->
        <a
            class="d-block"
            :class="{ 'disabled': isDisabled }"
            :style="{ 'margin': isMobile ? '-0.5rem' : '0.0rem', 'padding': isMobile ? '0.5rem' : '0.0rem' }"
            @click="model = !model"
        >
            <!-- background -->
            <div
                class="border"
                :class="{ 'bg-primary': model, 'bg-white': !model }"
                style="font-size: 1rem; height: 1.5em; width: 2.75em; border-radius: 999px; position: relative; transition: all ease-in-out 150ms;"
                :style="{ 'font-size': getFontSize() }"
            >
                <!-- dot -->
                <div
                    class="d-flex justify-content-center align-items-center position-relative"
                    style="width: 1em; height: 1em; border-radius: 100%; position: absolute; left: 50%; top: 50%; transition: all ease-in-out 150ms;"
                    :style="{ 'transform': model ? 'translate(calc(-50% + 0.625em), -50%)' : 'translate(calc(-50% - 0.625em), -50%)' }"
                >
                    <!-- spinner -->
                    <div class="d-flex justify-content-center align-items-center position-absolute inset-0" :style="{ 'opacity': isLoading ? '100%' : '0%', transition: 'all 100ms ease-in-out' }">
                        <div class="spinner-border spinner-border-sm" :style="{ 'color': model ? 'var(--bs-white)' : 'var(--bs-gray-500)' }" />
                    </div>
                    <!-- circle -->
                    <div class="d-flex justify-content-center align-items-center position-absolute inset-0" :style="{ 'opacity': isLoading ? '0%' : '100%', transition: 'all 100ms ease-in-out' }">
                        <div style="width: 1em; height: 1em; border-radius: 100%;" :style="{ 'background': model ? 'var(--bs-white)' : 'var(--bs-gray-500)' }" />
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<style scoped>
    .spinner-border {
        --bs-spinner-width: 1em;
        --bs-spinner-height: 1em;
        --bs-spinner-border-width: 0.2em;
    }
</style>
